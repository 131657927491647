import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { createSharing } from 'actions/access';
// import paths from 'paths';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import useDebouncedPenlyUserSearch from 'hooks/useDebouncedPenlyUserSearch';
import useStandardText from 'hooks/useStandardText';
// import AdvisorTextButtonGroup from './AdvisorTextButtonGroup';

const UserInviteForm = ({ defaultState = {}, initiator, onClose }) => {
    const topics = ['basic', 'pension', 'budget', 'relations', 'income', 'debt', 'savings', 'vehicles', 'properties'];
    const [name, setName] = useState(defaultState.name ?? '');
    const { email, userExists, handleEmailChange } = useDebouncedPenlyUserSearch(defaultState.email);
    const [confirmLegal, setConfirmLegal] = useState(false);
    const [scope, setScope] = useState(defaultState.scope ?? { type: 'self', basic: { all: true } });
    const [snack, setSnack] = useState();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const [message, setMessage] = useStandardText(userExists, defaultState.message, name, user.name );

    const handleCreateSharing = async () => {
        const firstName = name?.split(' ')[0];
        const sharingData = { recipient: { name, email, firstName }, initiator, requestedScopes: [scope], message };
        const sharing = await dispatch(createSharing(sharingData));
        if (!sharing.sharingId) throw new Error('bad');

        setSnack({ message: 'Der er nu blevet sendt en invitation til brugeren.', severity: 'success' });
        // setTimeout(() => navigate(paths.penlyAccessSharing.getPath(sharing.sharingId)), 3000);
        setTimeout(() => onClose(), 800);

    };

    const onChangeScope = (e) => {
        if(e.target.checked) return setScope({ ...scope, [e.target.name]: { all: true } });
        setScope({ ...scope, [e.target.name]: undefined });
    };

    return (
        <Grid>
            <h3>Modtagerens navn:</h3>
            <TextField
                label="Navn"
                value={ name }
                onChange={ (e) => setName(e.target.value) }
            />

            <h3>Modtagerens e-mailadresse:</h3>
            <TextField
                label="Email"
                value={ email }
                onChange={ (e) => handleEmailChange(e.target.value) }
            />
            { userExists &&
            <Box sx={{ display: 'inline', position:'absolute', paddingTop:'10px', paddingLeft:'8px' }}>
                <Chip
                    icon={ <VerifiedUserIcon color={ 'primary' } /> }
                    label={ 'Eksisterende Penly bruger' }
                />

            </Box>


            }

            <h3>Adgange:</h3>
            { topics.map((topic) => (
                <FormControlLabel
                    key={ topic }
                    label={ topic.charAt(0).toUpperCase() + topic.slice(1) }
                    control={
                        <Checkbox
                            name={ topic }
                            disabled={ topic === 'basic' }
                            checked={ !!scope[topic] }
                            onChange={ onChangeScope }
                        />
                    }
                />
            )) }

            <h3>Besked til modtager:</h3>
            { /* <AdvisorTextButtonGroup message={message} setMessage={setMessage}></AdvisorTextButtonGroup> */ }
            <TextField
                label="Skriv en besked til modtageren"
                value={ message }
                onChange={ (e) => setMessage(e.target.value) }
                variant="outlined"
                multiline
                rows={ 12 }
                fullWidth
            />

            <FormControlLabel
                label="Jeg bekræfter at jeg har fået tilladelse til at kontakte personen igennem Penly, og at jeg kan dokumentere dette."
                control={
                    <Checkbox
                        checked={ confirmLegal }
                        onChange={ () => setConfirmLegal(!confirmLegal) }
                    />
                }
            />
            <Button
                variant="contained"
                color="primary"
                style={{ display: 'block' }}
                onClick={ handleCreateSharing }
                disabled={ !confirmLegal }
            >
                Anmod
            </Button>
            <Snackbar
                open={ !!snack }
                autoHideDuration={ 6000 }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={ () => setSnack() }
            >
                <MuiAlert severity={ snack?.severity || 'success' }>{ snack?.message }</MuiAlert>
            </Snackbar>
        </Grid>
    );
};

export default UserInviteForm;
