import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { renderDate, renderKr } from 'utils/helpers';

const AftalerTable = ({ aftaler }) => (
    <Table aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell>Leverandør</TableCell>
                <TableCell align="right">Aftalenr.</TableCell>
                <TableCell align="right">Bemærkninger</TableCell>
                <TableCell align="right">Årlig indbetaling</TableCell>
                <TableCell align="right">Opsparing</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            { aftaler.map(row => (
                <TableRow key={ row.aftalenr + row.leverandoer }>
                    <TableCell component="th" scope="row">
                        { row.manglerOplysninger? 'Mangler oplysninger' : row.leverandoer }
                    </TableCell>
                    <TableCell align="right"><p className="tablePrimary">{ row.aftaleNr } </p> <p className="tableSecondary">{ row.aftaleTypeText }</p> </TableCell>
                    <TableCell align="right" style={{ whiteSpace: 'pre-line' }}>{ row.bemaerkninger }</TableCell>
                    <TableCell align="right"><p className="tablePrimary">{ renderKr(row.indbetaling) }</p> <p className="tableSecondary">{ renderDate(row.indbetalingDato,false) }</p></TableCell>
                    <TableCell align="right">{ row.opsparing ? (<div><p className="tablePrimary">{ renderKr(row.opsparing) }</p><p className="tableSecondary"> { renderDate(row.opsparingDato,false) }</p></div> ) : row.opsparingAlternativText }</TableCell>
                </TableRow>
            )) }
        </TableBody>
    </Table>
);

AftalerTable.propTypes = {
    aftaler: PropTypes.array.isRequired,
};

export default AftalerTable;
