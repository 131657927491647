import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { renderKr } from 'utils/helpers';

const UdbetalingerTable = ({ udbetalingsRaekker,perioder }) => (
    <Table style={{ width: '100%' }} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell>Leverandør</TableCell>
                <TableCell align="right">Aftalenr.</TableCell>
                <TableCell align="right">Bemærkninger</TableCell>
                { perioder.map(p => (
                    <TableCell align="right" key={ p.startAlder + p.slutAlder }>{ `${p.startAlder + (!!p.slutAlder && p.slutAlder !== 0 && (`-${  p.slutAlder}`))  } år` }</TableCell>
                )) }
            </TableRow>
        </TableHead>
        <TableBody>
            { udbetalingsRaekker.map(row => (
                <TableRow key={ row.aftalenr + row.leverandoer + row.bemaerkninger }>
                    <TableCell component="th" scope="row">
                        { row.manglerOplysninger? 'Mangler oplysninger' : row.leverandoer }
                    </TableCell>
                    <TableCell align="right"><p className="tablePrimary">{ row.aftaleNr } </p> <p className="tableSecondary">{ row.aftaleTypeText }</p> </TableCell>
                    <TableCell align="right" style={{ whiteSpace: 'pre-line' }}>{ row.bemaerkninger }</TableCell>
                    { row.udbetalinger.map(u => (
                        <TableCell align="right" key={ u.startAlder + u.slutAlder }>{ renderKr(u.beloeb) }</TableCell>
                    )) }
                </TableRow>
            )) }
        </TableBody>
    </Table>
);

UdbetalingerTable.propTypes = {
    udbetalingsRaekker: PropTypes.array,
    perioder: PropTypes.array,
};

export default UdbetalingerTable;
