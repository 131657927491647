import React from 'react';

import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import Routes from 'components/Routes';

import styled from '@emotion/styled';

import '../App.css';

const AppDiv = styled.div({
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const ContenDiv = styled.div({
    flex: '1 0 auto',
});

const FooterDiv = styled.div({
    backgroundColor: 'lightgrey',
    width: '100%',
    flexShrink: 0,
    textAlign: 'center',
});


function App() {
    return (
        <AppDiv>
            <ContenDiv>
                <Navigation />
                <Routes />
            </ContenDiv>
            <FooterDiv>
                <Footer />
            </FooterDiv>
            { /* <Error error={ error }/> */ }
        </AppDiv>
    );
}

export default App;
