import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import Clipboard from 'react-clipboard.js';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import paths from 'paths';
import { renderDateTime, mapStatus, getScrapeStatusOrValidity, mapScrapeErrorMessage } from 'utils/helpers';
import { colors } from 'utils/theme';

function ScrapesTable({ scrapes, navigate, onDelete }) {
    const [copied, setCopied] = React.useState(-1);

    const onClipboardSuccess = (id) => {
        setCopied(id);
        setTimeout(() => setCopied(-1), 2000);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const getPopupText = () => {
        const name = anchorEl && anchorEl.getAttribute('name');
        if(name ==='details'){
            return 'Se detaljer';
        }
        if(name ==='delete'){
            return 'Slet scrape';
        }
        return '';
    };

    const open = Boolean(anchorEl);

    return (
        <Table style={{ minWidth: 800 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Dato</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Navn</TableCell>
                    <TableCell align="center">CPR Nr.</TableCell>
                    <TableCell align="center">Udskrevet dato</TableCell>
                    <TableCell align="center">Token</TableCell>
                    <TableCell align="center"></TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                { scrapes.map((scrape) => {
                    if (scrape.status === 'new'){
                        return (
                            <TableRow key={ scrape.scrapeId }>
                                <TableCell align="center" colSpan={ 6 } >
                                    <div className="table-center">
                                        <CircularProgress  color="primary" size={ 20 } style={{ marginRight:5 }} /> <span className="table-center-text">Afventer behandling</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    }

                    if (scrape.status === 'running'){
                        return (
                            <TableRow key={ scrape.scrapeId }>
                                <TableCell align="center" colSpan={ 6 }>
                                    <div className="table-center">
                                        <CircularProgress  color="primary" size={ 16 } style={{ marginRight:5 }} /> <span className="table-center-text">Behandler</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    }

                    return (
                        <TableRow key={ scrape.scrapeId }>
                            <TableCell component="th" scope="row">
                                { renderDateTime(scrape.createdAt) }
                            </TableCell>
                            <TableCell align="center">{ mapStatus(getScrapeStatusOrValidity(scrape)) }</TableCell>


                            { scrape.status === 'finished' && (
                                <Fragment>
                                    <TableCell align="center">{ scrape.content.profil.navn }</TableCell>
                                    <TableCell align="center">{ scrape.content.profil.cprNr }</TableCell>
                                    <TableCell align="center">{ scrape.content.profil.udskrevetDato }</TableCell>
                                    <TableCell align="center">
                                        <Clipboard component='div' data-clipboard-text={ scrape.externalAccessToken } onSuccess={ () => onClipboardSuccess(scrape.scrapeId) }>
                                            <Chip
                                                id="clip"
                                                onClick={ () =>{} }
                                                icon={ copied === scrape.scrapeId ? (
                                                    <CheckIcon style={{ color:colors.positive }} />
                                                ) : (
                                                    <FileCopyOutlinedIcon style={{ color:colors.black }} />
                                                ) }
                                                label={ copied === scrape.scrapeId ? 'Kopieret' : scrape.externalAccessToken }
                                            />
                                        </Clipboard>
                                    </TableCell>
                                </Fragment>
                            ) }

                            { scrape.status === 'failed' && (
                                <>
                                    <TableCell align="center" colSpan={ 4 }>{ mapScrapeErrorMessage(scrape.error) }</TableCell>
                                </>
                            ) }

                            { scrape.status === 'deleted' && (
                                <>
                                    <TableCell align="center" colSpan={ 4 }></TableCell>
                                </>
                            ) }

                            <TableCell align="center">
                                <FindInPageOutlinedIcon
                                    aria-owns={ open ? 'mouse-over-popover' : undefined }
                                    aria-haspopup="true"
                                    name="details"
                                    onMouseEnter={ handlePopoverOpen }
                                    onMouseLeave={ handlePopoverClose }

                                    style={{ color:colors.black, cursor:'pointer', marginRight:5, marginLeft:5 }}
                                    onClick={ () => navigate(paths.pensionsInfoScrape.getPath(scrape.scrapeId)) }
                                />
                                <DeleteIcon
                                    aria-owns={ open ? 'mouse-over-popover' : undefined }
                                    aria-haspopup="true"
                                    name="delete"
                                    onMouseEnter={ handlePopoverOpen }
                                    onMouseLeave={ handlePopoverClose }

                                    style={{ color: colors.black, cursor:'pointer', marginRight:5, marginLeft:5  }}
                                    onClick={ () => onDelete(scrape) } />
                            </TableCell>
                        </TableRow>
                    );
                },

                ) }
                <Popover
                    id="mouse-over-popover"
                    style={{ pointerEvents: 'none' }}
                    open={ open }
                    anchorEl={ anchorEl }
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={ handlePopoverClose }
                    disableRestoreFocus
                >
                    <Typography>{ getPopupText() }</Typography>
                </Popover>
            </TableBody>
        </Table>
    );
}
ScrapesTable.propTypes = {
    scrapes: PropTypes.array,
    navigate: PropTypes.func,
    onDelete: PropTypes.func,
};

export default ScrapesTable;
