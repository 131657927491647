import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Section from 'components/Section';
import Content from 'components/Content';
import useAuthentication from 'utils/useAuthentication';
import { createOrganisation } from 'actions/organisations';

const schema = yup.object().shape({
    name: yup.string().required('Navn er påkrævet').min(2, 'Navn er påkrævet'),
    cvr: yup.string().required('CVR-nummer er påkrævet').min(8, 'CVR-nummer er påkrævet'),
    description: yup.string(),
    accepted: yup.boolean().oneOf([true], 'Forretningsbetingelserne skal godkendes'),
});

function NoReadyOrganisation() {
    const organisation = useSelector(state => state.auth.user.organisation);
    const { logout } = useAuthentication();
    const dispatch = useDispatch();

    const { control, handleSubmit } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
    });

    const onSubmit = ({ name, cvr, description }) => dispatch(createOrganisation({ name, cvr, description }));

    return (
        <Content>
            { !organisation && (
                <Section>
                    <h1>Velkommen til Teal Business</h1>
                    <h2>Først skal vi kende din virksomhed</h2>
                    <p>
                        For at benytte Teal Business skal din virksomhed være registreret og godkendt af Teal ApS.
                        Dette gør vi for at beskytte vores services imod misbrug.
                    </p>
                    <p>
                        Er du ansat i en virksomhed der allerede bruger Teal Business, så kontakt os for at blive tilføjet til virksomheden.
                    </p>
                    <p>
                        Hvis du har nogen spørgsmål, så kan du kontakte os på <a href="mailto:support@tealco.dk">support@tealco.dk</a>.
                    </p>

                    <form onSubmit={ handleSubmit(onSubmit) }>
                        <Controller
                            name="name"
                            control={ control }
                            render={ ({ field, fieldState }) => (
                                <TextField
                                    { ...field }
                                    label="Virksomhedsnavn *"
                                    style={{ marginRight: 10 }}
                                    variant="filled"
                                    placeholder='Teal ApS'
                                    error={ Boolean(fieldState.error) }
                                    helperText={ fieldState.error?.message || ' ' }
                                />
                            ) }
                        />
                        <Controller
                            name="cvr"
                            control={ control }
                            render={ ({ field, fieldState }) => (
                                <TextField
                                    { ...field }
                                    label="CVR-nummer *"
                                    variant="filled"
                                    placeholder='DK-39063158'
                                    error={ Boolean(fieldState.error) }
                                    helperText={ fieldState.error?.message || ' ' }
                                />
                            ) }
                        />
                        <Controller
                            name="description"
                            control={ control }
                            render={ ({ field }) => (
                                <TextField
                                    { ...field }
                                    style={{ marginTop: 10 }}
                                    label="Om jer - fortæl hvor I kender Teal Business fra, og andet der kan hjælpe os med at godkende jer."
                                    variant="filled"
                                    fullWidth
                                    multiline={ true }
                                    minRows={ 4 }
                                />
                            ) }
                        />
                        <Controller
                            name="accepted"
                            control={ control }
                            defaultValue={ false }
                            render={ ({ field: { value, ...field }, fieldState }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        style={{ marginTop: 20 }}
                                        control={ <Checkbox checked={ value } { ...field } /> }
                                        label={ <>Jeg godkender <a href="/terms-1-1.html">forretningsbetingelserne</a> på vegne af min virksomhed.</> }
                                    />
                                    <FormHelperText error={ Boolean(fieldState.error) }>
                                        { fieldState.error?.message || ' ' }
                                    </FormHelperText>
                                </FormGroup>
                            ) }
                        />
                        <Button variant="contained" type='submit' style={{ marginTop: 20, minWidth: 260 }}>
                            Opret
                        </Button>
                        <Button onClick={ logout } style={{ marginTop: 20, minWidth: 260, marginLeft: 20 }}>
                            Log ud
                        </Button>

                    </form>
                </Section>
            ) }
            { organisation && !organisation.verified && (
                <Section title='Du kan snart bruge Teal Business'>
                    <p>
                        Din virksomhed er ved at blive godkendt. Vi kontakter dig når godkendelsen er gennemført.<br/>
                        Har du spørgsmål eller kommentarer, så kontakt os på <a href="mailto:support@tealco.dk">support@tealco.dk</a>.
                    </p>
                </Section>
            ) }

            { organisation && organisation.verified && !organisation.active && (
                <Section title='Du kan ikke bruge Teal Business'>
                    <p>
                        Din virksomheds adgang er blevet suspenderet.
                        Kontakt os på <a href="mailto:support@tealco.dk">support@tealco.dk</a>.
                    </p>
                </Section>
            ) }

            { organisation && (
                <Section>
                    <Table style={{ marginTop: '20px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Virksomhedsnavn:</TableCell>
                                <TableCell>Virksomheds ID:</TableCell>
                                <TableCell>CVR-nummer:</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{ organisation.name }</TableCell>
                                <TableCell><span style={{ fontFamily: 'Courier, monospace' }} >{ organisation.organisationId }</span></TableCell>
                                <TableCell>{ organisation.cvr }</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Section>
            ) }
        </Content>
    );
}

export default NoReadyOrganisation;
