import { combineReducers } from 'redux';
import auth from './auth';
import accessOrders from '../slices/orders';
import organisationUsers from '../slices/users';

export default combineReducers({
    auth,
    accessOrders: accessOrders.reducer,
    organisationUsers: organisationUsers.reducer,
});
