import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const Section = (props) => {
    const [open, setOpen] = useState(props.open);
    return (
        <div className="App-section" style={ props.style }>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h5" style={{ fontWeight: 700 }} id={ props.id || 'section-title' }>
                    { props.title }
                </Typography>
                { props.closeable && (
                    <IconButton style={{ paddingTop: 0, paddingBottom: 0 }} onClick={ () => setOpen(!open) }>
                        { open ? <RemoveIcon /> : <AddIcon /> }
                    </IconButton>
                ) }
            </div>


            <Collapse
                in={ open }
                timeout="auto"
                unmountOnExit
            >
                <div className="paper">
                    <div className="paper-description">
                        { props.description }
                    </div>

                    <div className={ `paper-content${  props.centered ? ' centered' : ''}` }>
                        { props.loading && (
                            <div className="App-section-loading">
                                <CircularProgress  color="primary" size={ 40 } />
                            </div>
                        ) }
                        { !props.loading && props.error && (
                            <div>
                                <Typography variant="h6" >
                                    Der skete en fejl!
                                </Typography>
                                { props.error.message }
                            </div>
                        ) }
                        { !props.loading && !props.error && (
                            props.children
                        ) }
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

Section.propTypes = {
    id: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.node,
    loading: PropTypes.bool,
    centered: PropTypes.bool,
    children: PropTypes.node,
    error: PropTypes.any,
    style: PropTypes.any,
    open: PropTypes.bool,
    closeable: PropTypes.bool,
};

Section.defaultProps = {
    open: true,
    closeable: false,
};

export default Section;
