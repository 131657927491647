import React from 'react';
import PropTypes from 'prop-types';
import { Card, Unstable_Grid2 as MuiGrid } from '@mui/material';

export function GridCard({ children, ...props }) {
    return (
        <MuiGrid>
            <Card style={{ backgroundColor: '#eee', height: '100%' }} { ...props }>
                { children }
            </Card>
        </MuiGrid>
    );
}

GridCard.propTypes = {
    children: PropTypes.node.isRequired,
};

export const Grid = MuiGrid;

Grid.propTypes = {
    children: PropTypes.node.isRequired,
};

export function GridContainer({ children, ...props }) {
    return (
        <MuiGrid { ...props } container spacing={ 1 } alignItems='stretch'>
            { children }
        </MuiGrid>
    );
}

GridContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
