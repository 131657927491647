import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { renderDate } from 'utils/helpers';

const SharingsTable = ({ sharings, getSharingPath }) => (
    <TableContainer>
        <Table caria-label="simple table">
            <TableBody>
                { sharings.map((sharing) => (
                    <TableRow key={ sharing.sharingId }>

                        { (sharing.status === 'pending' && (
                            <>
                                <TableCell align="left">Afventer accept</TableCell>
                                <TableCell align="left">{ sharing.exporter.email }</TableCell>
                                <TableCell align="left">Anmodet dato: { renderDate(sharing.createdAt) }</TableCell>
                            </>
                        )) || (sharing.status === 'accepted' && (
                            <>
                                <TableCell align="left">Accepteret</TableCell>
                                <TableCell align="left">{ sharing.exporter.name }</TableCell>
                                <TableCell align="left">Udløber dato: { renderDate(sharing.expiry) }</TableCell>
                            </>
                        )) || (
                            <>
                                <TableCell align="left">Ophævet</TableCell>
                                <TableCell />
                                <TableCell align="left">Ophævet dato: { renderDate(sharing.updatedAt) }</TableCell>
                            </>
                        ) }
                        <TableCell align="left">{ JSON.stringify(sharing.scope) }</TableCell>
                        <TableCell sx={{ p: 0 }} align="right">
                            <Button component={ RouterLink } to={{ pathname: getSharingPath(sharing.sharingId) }} variant="contained">Åben</Button>
                        </TableCell>
                    </TableRow>
                )) }
            </TableBody>
        </Table>
    </TableContainer>
);


SharingsTable.propTypes = {
    sharings: PropTypes.array.isRequired,
    getSharingPath: PropTypes.func.isRequired,
};

export default SharingsTable;
