import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllScrapes, createScrape, deleteScrape } from 'actions/scrapes';

import sleep from 'utils/sleep';

import Section from 'components/Section';
import Confirm from 'components/Confirm';
import Content from 'components/Content';

import PensionsInfoUploader from './PensionsInfoUploader';
import ScrapesTable from './ScrapesTable';


const PensionsInfoPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [scrapes, setScrapes] = useState();
    const [uploading, setUploading] = useState();
    const [success, setSuccess] = useState();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState();

    useEffect(() => {
        const run = async () => {
            const allScrapes = await dispatch(getAllScrapes());
            setScrapes(allScrapes.filter(x => x.source !== 'api'));
        };
        run();
    }, [dispatch]);

    useEffect(() => {
        const notEnded = scrapes?.filter(s => ['new', 'running'].includes(s.status));
        if (notEnded?.length) {
            const run = async () => {
                await sleep(2 * 1000);
                const allScrapes = await dispatch(getAllScrapes());
                setScrapes(allScrapes);
            };
            run();
        }
    }, [dispatch, scrapes]);

    const onDrop = async (acceptedFiles) => {
        setUploading(true);
        const pdf = acceptedFiles[0];
        const newScrape = await dispatch(createScrape(pdf));
        setScrapes([newScrape, ...scrapes]);
        setSuccess(true);
        setUploading(false);
    };

    const onOpenDeleteConfirm = (scrape) => {
        setShowDeleteConfirm(scrape);
    };

    const onCloseDeleteConfirm = () => setShowDeleteConfirm();

    const onDeleteScrape = async (scrape) => {
        onCloseDeleteConfirm();
        await dispatch(deleteScrape(scrape.scrapeId));
        const newScrapes = scrapes.filter(s => s.scrapeId !== scrape.scrapeId );
        setScrapes(newScrapes);
    };
    return (
        <Content>
            <Section
                title={ 'PI scraper' }
                description={ <><em>PensionsInfo-scraperen</em> er ikke funktionel for rapporter genereret efter 10.11.2023,
                    som følge af at PensionsInfo har opdateret deres rapportopsætning.
                    Vi henviser brugere til at benytte MidID flowet i stedet for.</> }
            >
                <PensionsInfoUploader
                    loading={ uploading }
                    success={ success }
                    lastToken={ scrapes && scrapes[0] && scrapes[0].externalAccessToken }
                    onTokenCopyClick={ () => {} }
                    onDrop={ onDrop }
                />
            </Section>
            <Section
                loading={ !scrapes }
                title={ 'Seneste Scrapes' }
                description="Her finder du en liste af igangværende og tidligere scrapes"
            >
                <ScrapesTable
                    scrapes={ scrapes }
                    onDelete={ row => onOpenDeleteConfirm(row) }
                    navigate={ navigate }
                />
            </Section>
            <Confirm
                text={{
                    title: 'Slet scrape?',
                    content: 'Er du sikker på at du vil slette dette scrape?',
                    yes: 'Ja - Slet',
                    no: 'Nej',
                }}
                open={ Boolean(showDeleteConfirm) }
                handleClose={ onCloseDeleteConfirm }
                onCancel={ onCloseDeleteConfirm }
                onConfirm={ () => onDeleteScrape(showDeleteConfirm) }
            />
        </Content>
    );
};

export default PensionsInfoPage;
