import React from 'react';

import Button from '@mui/material/Button';
import Section from 'components/Section';
import Content from 'components/Content';
import Footer from 'components/Footer';

import useAuthentication from 'utils/useAuthentication';

const Login = () => {
    const { login, register } = useAuthentication();
    return (
        <Content>
            <Section
                style={{ maxWidth: 500 }}
                centered
            >
                <div>
                    <img style={{ height: 50, marginRight: 45 }} src="/TealBusinessLogo.svg" alt="Teal Business" />
                </div>
                <Button variant="contained" color="primary" style={{ width: 200, marginTop: 20 }} size='large' onClick={ () => login({ redirectPath: window.location.pathname }) } >
                    Log ind
                </Button>
                <p style={{ marginTop: 20 }}>
                    Ny bruger? <a href='/#'onClick={ register }>Tilmeld dig</a>
                </p>

                <p>
                    <a href="/terms-1-1.html">Forretningsbetingelser</a>
                </p>
            </Section>
            <div style={{ paddingLeft: 20, paddingRight: 20, backgroundColor: 'lightgrey' }}>
                <Footer/>
            </div>
        </Content>
    );
};

Login.propTypes = { };

export default Login;
