import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Account from 'pages/Account';
import Organisation from 'pages/Organisation';
import Integrations from 'pages/Integrations';
import PenlyAccess from 'pages/PenlyAccess';
import PenlyAccessUser from 'pages/PenlyAccessUser';
import PenlyAccessSharing from 'pages/PenlyAccessSharing';
import PenlyAccessOrder from 'pages/PenlyAccessOrder';

import PensionsInfoScraper from 'pages/PensionsInfoScraper';
import PensionsInfoScrape from 'pages/PensionsInfoScrape';

import paths from 'paths';

const Routing = () => (
    <Routes>
        <Route exact path={ paths.dashboard.path } element={ <PenlyAccess /> } />

        <Route exact path={ paths.account.path } element={ <Account /> } />
        <Route exact path={ paths.organisation.path } element={ <Organisation /> } />
        <Route exact path={ paths.integrations.path } element={ <Integrations /> } />
        <Route exact path={ paths.penlyAccess.path } element={ <PenlyAccess /> } />
        <Route exact path={ paths.penlyAccessUser.path } element={ <PenlyAccessUser /> } />
        <Route exact path={ paths.penlyAccessSharing.path } element={ <PenlyAccessSharing /> } />
        <Route exact path={ paths.penlyAccessOrder.path } element={ <PenlyAccessOrder /> } />

        <Route exact path={ paths.pensionsInfoScraper.path } element={ <PensionsInfoScraper /> } />
        <Route exact path={ paths.pensionsInfoScrape.path } element={ <PensionsInfoScrape /> } />
    </Routes>
);

export default Routing;
