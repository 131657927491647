import styled from '@emotion/styled';

const Content = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
});

export default Content;
