import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import CircularProgress from '@mui/material/CircularProgress';
import Clipboard from 'react-clipboard.js';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { colors } from 'utils/theme';
import styled from '@emotion/styled';

const ContentLinkDiv = styled.div({
    marginTop: 0,
    textDecoration: 'underline',
    cursor: 'pointer',
});

const PensionsInfoUploader = ({ success, loading, onDrop, lastToken }) => {
    const handleOnDrop = useCallback(acceptedFiles => {
        onDrop(acceptedFiles);
    }, [onDrop]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop: handleOnDrop });
    const [copied, setCopied] = useState(false);
    const onClipboardSuccess = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const onCopyToken = (event) => event.stopPropagation();

    return (
        <div { ...getRootProps() } className="pi-uploader" style={{ backgroundColor:colors.uploadBackground }}>
            <input { ...getInputProps() }  className="pi-uploader-input"/>
            { loading && (
                <div className="pi-uploader-content">
                    <CircularProgress color="primary"  size={ 40 } style={{ marginLeft:20 }} />
                    <ContentLinkDiv>
                        Upload flere
                    </ContentLinkDiv>
                </div>
            ) }
            { success && (
                <div className="pi-uploader-content">
                    <img src='/static/gfx/pdf-success.svg' className="pi-uploader-img" alt="pdf success" style={{ height: 150 }} />
                    <div className="pi-uploader-content-text">
                        PensionsInfo dokumentet er uploadet successfuldt
                    </div>

                    <div className="pi-uploader-content-success-link" >
                        <span style={{ color:colors.black }} className="pi-uploader-content-success-link-text">
                            Kopier token til din klipholder
                        </span>
                        <ArrowRightAltIcon style={{ margin:5, color:colors.black, fontSize:30 }} />

                        <Clipboard onClick={ (e) => onCopyToken(e) } component='div' data-clipboard-text={ lastToken } onSuccess={ (e) => onClipboardSuccess(e) }>
                            <Chip
                                id="clip"
                                onClick={ () =>{} }
                                icon={ copied === true ? <CheckIcon style={{ color:colors.positive }}></CheckIcon> :<FileCopyOutlinedIcon style={{ color:colors.black }}></FileCopyOutlinedIcon> }
                                label={ copied === true ? 'Kopieret': lastToken }
                            />
                        </Clipboard>
                    </div>

                    <ContentLinkDiv>
                        Upload ny
                    </ContentLinkDiv>

                </div>
            ) }
            { !loading && !success && (
                <div className="pi-uploader-content">
                    <img src='/static/gfx/pdf.svg' className="pi-uploader-img" alt="pdf" style={{ height: 150 }}/>
                    <div className="pi-uploader-content-text">
                        Træk eller vælg PensionsInfo dokumentet
                    </div>

                    <ContentLinkDiv>
                        Vælg fil
                    </ContentLinkDiv>
                </div>
            ) }
        </div>
    );
};

PensionsInfoUploader.propTypes = {
    success: PropTypes.bool,
    loading: PropTypes.bool,
    onDrop: PropTypes.func,
    lastToken:PropTypes.string,
};

export default PensionsInfoUploader;
