import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import MobileStepper from '@mui/material/MobileStepper';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import useWindowSize from 'hooks/useWindowSize';
import Box from '@mui/material/Box';

const QontoConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ef9761',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ef9761',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eeeeee',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ ownerState }) => ({
    color: '#eeeeee',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#ef9761',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#ef9761',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={ className }>
            { completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            ) }
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
   * Whether this step is active.
   * @default false
   */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
    completed: PropTypes.bool,
};



export default function ProgressStepper( { activeStep, steps, id }) {
    const size = useWindowSize();
    if(size.width < 500){
        return (
            <Box sx={{ maxWidth: 400, flexGrow: 1, display: 'flex', justifyContent:'center' }}>
                <div style={{ maxWidth: 400, flexGrow: 1, display: 'flex', justifyContent:'center', fontWeight:'500' }}>
                    <span style={{ position: 'relative', top:'7px', fontSize: '17px' }}>
                        Trin
                    </span>

                    <MobileStepper
                        variant="text"
                        steps={ steps.length }
                        position="static"
                        activeStep={ activeStep }
                    />
                </div>

            </Box>
        );
    }
    return (
        <Stack sx={{ width: '100%' }} spacing={ 4 }>
            <Stepper  id={ id } alternativeLabel activeStep={ activeStep } connector={ <QontoConnector /> }>
                { steps.map((label) => (
                    <Step key={ label }>
                        <StepLabel StepIconComponent={ QontoStepIcon }>{ label }</StepLabel>
                    </Step>
                )) }
            </Stepper>
        </Stack>
    );
}
