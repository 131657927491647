import React from 'react';
import { config } from 'utils/config';

const SiteFooter = () => {
    if (config.env === 'prod') return null;

    return (
        <p>
            <b>{ config.env } build - version { process.env.REACT_APP_VERSION }</b>
        </p>
    );
};

export default SiteFooter;
