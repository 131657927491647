import { useEffect, useState } from 'react';
import { getFirstName } from 'utils/formatter';
import { messageWithUser, messageWithoutUser } from '../utils/advisorTextOptions';

const useStandardText = (userExists, defaultMessage, userName, advisorName) => {
    const [message, setMessage] = useState(defaultMessage ?? '');

    useEffect(() => {
        if (userExists) {
            setMessage(messageWithUser(userName, getFirstName(advisorName)));
        } else {
            setMessage(messageWithoutUser(userName, getFirstName(advisorName)));
        }
    }, [userExists, userName, advisorName]);

    return [message, setMessage];
};

export default useStandardText;
