import React from 'react';
import ProgressStepper from './ProgressStepper';

const steps = [
    'Bestilling',
    'Mødebooking',
    'Dataindsamling',
    'Klar til møde',
];

export default function OrderProgressStepper( { activeStep }) {
    return (
        <ProgressStepper
            id="order-progress-stepper"
            steps={ steps }
            activeStep={ activeStep }
        />
    );
}
