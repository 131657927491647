import { atob, btoa } from 'js-base64';
import Cookie from 'js-cookie';

const Persistor = () => ({
    setTokens: ({ refreshToken, token, idToken }) => {
        if (refreshToken) {
            Cookie.set('kcTbRefreshToken', btoa(refreshToken));
        }
        if (idToken) {
            Cookie.set('kcTbIdToken', btoa(idToken));
        }
        if (token) {
            Cookie.set('kcTbToken', btoa(token));
        }
    },
    getTokens: () => {
        const result = {
            refreshToken: Cookie.get('kcTbRefreshToken'),
            idToken: Cookie.get('kcTbIdToken'),
            token: Cookie.get('kcTbToken'),
        };

        if (result.refreshToken) result.refreshToken = atob(result.refreshToken);
        if (result.idToken) result.idToken = atob(result.idToken);
        if (result.token) result.token = atob(result.token);

        return result;
    },
    resetTokens: () => {
        Cookie.remove('kcTbRefreshToken');
        Cookie.remove('kcTbIdToken');
        Cookie.remove('kcTbToken');
    },
});

export default Persistor();
