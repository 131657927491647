import React, { createContext, useContext, useState } from 'react';
import propTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingContext = createContext(null);

export function LoadingProvider({ children }) {
    const [loading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider value={ setLoading }>
            { children }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, flexDirection: 'column' }}
                open={ Boolean(loading) }
                transitionDuration={ 1000 }
            >
                { loading && (
                    <>
                        <h1>{ typeof loading === 'string' ? loading : 'Indlæser' }</h1>
                        <CircularProgress color="inherit" />
                    </>
                ) }

            </Backdrop>
        </LoadingContext.Provider>
    );
}

LoadingProvider.propTypes = {
    children: propTypes.node.isRequired,
};

export function useLoading() {
    const setLoading = useContext(LoadingContext);

    if (!setLoading) throw new Error('useLoading must be used within LoadingProvider');

    return setLoading;
}
