import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/material';

import Orders from 'components/access/Orders';
import Users from 'components/access/Users';
import Sharings from 'components/access/Sharings';

const PenlyAccess = () => {
    const [value, setValue] = useState(getCurrentTabFromHash());

    function getCurrentTabFromHash() {
        if(!window.location.hash) return 'ordrer';
        return window.location.hash.replace('#', '');
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.location.hash = newValue;
    };

    return (
        <div className="App-content">
            <Tabs value={ value } onChange={ handleChange } indicatorColor="primary">
                <Tab label="Ordrer" value="ordrer" />
                <Tab label="Brugere" value="brugere" />
                <Tab label="Delinger" value="delinger" />
            </Tabs>

            <TabPanel value={ value } index={ 'ordrer' }>
                <Orders />
            </TabPanel>

            <TabPanel value={ value } index={ 'brugere' }>
                <Users />
            </TabPanel>

            <TabPanel value={ value } index={ 'delinger' }>
                <Sharings />
            </TabPanel>
        </div>
    );
};

function TabPanel(props) {
    const { children, value, index } = props;

    return <div hidden={ value !== index }>{ value === index && <Box p={ 3 }>{ children }</Box> }</div>;
}

export default PenlyAccess;
