import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { renderDate } from 'utils/helpers';

const ProfilTable = ({ profil }) => (
    <div className="profil-wrapper">
        <div className="profil-container">
            <div className="profil-item">
                <Typography variant="subtitle2">Navn</Typography > <p>{ profil.navn }</p>
            </div>

            <Divider></Divider>
            <div className="profil-item">
                <Typography variant="subtitle2">CPR nr.</Typography > <p>{ profil.cprNr }</p>
            </div>

            <Divider></Divider>
            <div className="profil-item">
                <Typography variant="subtitle2">Udskrevet dato</Typography > <p>{ renderDate(profil.udskrevetDato, false) }</p>
            </div>

        </div>
    </div>
);

ProfilTable.propTypes = {
    profil: PropTypes.shape({
        navn: PropTypes.string.isRequired,
        cprNr: PropTypes.string.isRequired,
        udskrevetDato: PropTypes.string.isRequired,
    }),
};

export default ProfilTable;
