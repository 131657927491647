/* eslint-disable react/prop-types, no-nested-ternary */

import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import FormControl from '@mui/material/FormControl';
import { uploadFile } from 'actions/access';

const useStyles = makeStyles(theme => ({
    spacing: { marginTop: theme.spacing(2) },
    dropzone: {
        height: '100px',
        cursor: 'pointer',
        borderStyle: 'solid',
        borderColor: '#AAA',
        border: 1,
        background: 'var(--color-containersecondary)',
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dropzoneContent:{
        margin: 0,
        fontSize: '14px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

export default function UploadFileModal({ open, handleClose, maxFileSize = 5000000, defaultFileType, userId }) {
    const [file, setFile] = React.useState(undefined);
    const [fileType, setFileType] = React.useState(defaultFileType || 'Advice');
    const [errorMessage, setErrorMessage] = React.useState();
    const styles = useStyles();
    const dispatch = useDispatch();

    const handleSave = async () => {
        if(file.size < maxFileSize) {
            file.fileType = fileType;
            const uploadedFile = await dispatch(uploadFile([file], [], fileType, userId));
            setFile(undefined);
            setFileType('default');
            handleClose(uploadedFile);
        } else {
            setErrorMessage('Filen overskrider den maksimale filstørrelse på 5 MB');
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            <Dialog open={ open } onClose={ () => handleClose() } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><h5>Upload fil</h5></DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{ color: '#000000', fontSize: '16px' }}>
                        Upload en fil som deles med brugeren og kan tilgås under Dokumenter på brugerens Penly profil.
                    </DialogContentText>
                    <div className={ styles.dropzone }  style={{ backgroundColor:'#eeeeee', color:'black',marginTop:20 }}{ ...getRootProps() }>
                        <div>
                            { file ? (
                                <p className={ styles.dropzoneContent }>{ file.name }</p>
                            ) : isDragActive ? (
                                <p className={ styles.dropzoneContent }>Træk en fil hertil for at uploade</p>
                            ) : (
                                <p className={ styles.dropzoneContent }>Træk en fil hertil for at uploade, eller klik for at vælge fil</p>
                            ) }
                        </div>
                    </div>
                    { errorMessage ? <p style={{ color: 'var(--color-error)', paddingTop: '8px' }}>{ errorMessage }</p> : null }
                    <FormControl className={ styles.formControl }>
                        <input { ...getInputProps() } className="pi-uploader-input" />
                        { /* <InputLabel htmlFor="type-native-simple">Type</InputLabel>
                        <Select
                            value={ fileType }
                            disabled={lockFileType}
                            onChange={ handleFileTypeChange }
                            inputProps={{
                                name: 'type',
                                id: 'type-native-simple',
                            }}
                        >
                            <MenuItem disabled selected value="default">Vælg filtype</MenuItem>
                            { fileTypeArr.map(({ key, value }) => (
                                <MenuItem value={ key } key={ key }>
                                    { value }
                                </MenuItem>
                            )) }
                        </Select> */ }
                    </FormControl>
                    <DialogActions>
                        <Button
                            onClick={ () => {
                                handleClose();
                                setFile(undefined);
                                setFileType('default');
                                setErrorMessage('');
                            } }
                            color="primary" variant="contained">
                            Fortryd
                        </Button>
                        <Button
                            onClick={ handleSave }
                            color="primary"
                            variant="contained"
                            disabled={ !file || fileType === 'default' }>
                            Upload
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}
