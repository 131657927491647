import { teal } from 'api';

export function initializeAuthentication() {
    return { type: 'AUTH_INIT' };
}

export function login() {
    return { type: 'AUTH_REQUEST_LOGIN' };
}

export function logout() {
    return { type: 'AUTH_REQUEST_LOGOUT' };
}

export function register() {
    return { type: 'AUTH_REQUEST_REGISTER' };
}

export function getUser() {
    return async (dispatch) => {
        let user;
        try {
            user = await teal.getUser();
        } catch(error) {
            return error;
        }

        dispatch({ type: 'AUTH_UPDATED', payload: { user } });

        return user;
    };
}
