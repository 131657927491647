import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { renderDateTime, mapStatus } from 'utils/helpers';
import PdfDialog from 'components/PdfDialog';
import Confirm from 'components/Confirm';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteScrape } from 'actions/scrapes';

import { teal } from 'api';

const ScrapeDetails = ({ scrape }) => {
    const [openJson, setOpenJson] = React.useState(false);
    const [pdf, setPdf] = React.useState();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const toggleShowJsonModal = () => setOpenJson(!openJson);
    const toggleShowPdfModal = async () => {
        const pdfUrl = await createPdfDataUrl();
        setPdf({ url: pdfUrl });
    };

    const downloadJsonFile = () => {
        const content = JSON.stringify(scrape.content, null, 2);
        const url = window.URL.createObjectURL(new Blob([content], { type : 'application/json' }));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        a.download = `${scrape.scrapeId  }.json`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const createPdfDataUrl = async () => {
        const content = await teal.getScrapeFile({ scrapeId: scrape.scrapeId });
        const url = window.URL.createObjectURL(new Blob([content]));
        return url;
    };

    const downloadPDFFile = async () => {
        const url = await createPdfDataUrl();
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        a.download = `${scrape.scrapeId  }.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    const onCloseDeleteConfirm = () => setShowDeleteConfirm();

    const onDeleteScrape = async (scrape) => {
        onCloseDeleteConfirm();
        dispatch(deleteScrape(scrape.scrapeId));
        navigate(-1);
    };
    return (
        <div className="profil-wrapper">
            <div className="profil-container">
                { scrape.content && (
                    <>
                        <div className="profil-item">
                            <Typography variant="subtitle2">Token</Typography > <p style={{ maxWidth: 280, overflowWrap: 'break-word', textAlign: 'right' }}>{ scrape.externalAccessToken }</p>
                        </div>
                        <Divider />
                    </>
                ) }

                <div className="profil-item">
                    <Typography variant="subtitle2">Status</Typography>
                    <p>{ mapStatus(scrape.status) }</p>
                </div>

                <Divider />

                <div className="profil-item">
                    <Typography variant="subtitle2">Uploadet tidspunkt</Typography>
                    <p>{ renderDateTime(scrape.createdAt) }</p>
                </div>

                { scrape.status !== 'deleted' && (
                    <>
                        <Divider/>
                        <div className="profil-item">
                            <Typography variant="subtitle2">Handlinger</Typography>
                            <span style={{ display: 'flex', justfifyContent: 'flex-end' }}>
                                <Button style={{ marginRight: 5 }} variant="outlined" onClick={ downloadPDFFile }>Hent</Button>
                                <Button style={{ marginRight: 5 }} variant="outlined" onClick={ toggleShowPdfModal }>Vis fil</Button>
                                { scrape.content && <Button style={{ marginRight: 5 }} variant="outlined" onClick={ toggleShowJsonModal }>Vis JSON</Button> }
                                <Button style={{ marginRight: 5 }} variant="outlined" color="error" onClick={ () => setShowDeleteConfirm(scrape) } >Slet</Button>

                            </span>
                        </div>
                    </>
                ) }

                <Dialog
                    fullScreen={ fullScreen }
                    open={ openJson }
                    onClose={ toggleShowJsonModal }
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{ 'Data fra scraper' }</DialogTitle>
                    <DialogContent>
                        <div className='code' disabled style={{ minHeight: '60vh' }}>
                            { JSON.stringify(scrape.content, null, 2) }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ downloadJsonFile } color="primary" autoFocus>
                            Download
                        </Button>
                        <Button onClick={ toggleShowJsonModal } color="primary" autoFocus>
                            Luk
                        </Button>
                    </DialogActions>
                </Dialog>

                <PdfDialog pdf={ pdf } onClose={ () => setPdf() } />
                <Confirm
                    text={{
                        title: 'Slet scrape?',
                        content: 'Er du sikker på at du vil slette dette scrape?',
                        yes: 'Ja - Slet',
                        no: 'Nej',
                    }}
                    open={ Boolean(showDeleteConfirm) }
                    handleClose={ onCloseDeleteConfirm }
                    onCancel={ onCloseDeleteConfirm }
                    onConfirm={ () => onDeleteScrape(showDeleteConfirm) }
                />
            </div>
        </div>
    );
};

ScrapeDetails.propTypes = {
    scrape: PropTypes.object,
};

export default ScrapeDetails;
