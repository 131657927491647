export default {
    dashboard: { path: '/', getPath: () => '/' },

    account: { path: '/account', getPath: () => '/account' },

    organisation: { path: '/organisation', getPath: () => '/organisation' },

    pensionsInfoScraper: { path: '/pensions-info-scraper', getPath: () => '/pensions-info-scraper' },
    pensionsInfoScrape: { path: '/pensions-info-scraper/:scrapeId', getPath: scrapeId => `/pensions-info-scraper/${scrapeId}` },

    penlyAccess: { path: '/access', getPath: () => '/access', index: 1 },
    integrations: { path: '/integrations', getPath: () => '/integrations', index: 1 },
    penlyAccessUser: { path: '/access/users/:userId', getPath: userId => `/access/users/${userId}` },
    penlyAccessSharing: { path: '/access/sharings/:sharingId', getPath: sharingId => `/access/sharings/${sharingId}` },
    penlyAccessOrder: { path: '/access/orders/:orderId', getPath: orderId => `/access/orders/${orderId}` },
};
