import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';

import api from 'api';

const ordersAdapter = createEntityAdapter({
    selectId: (order) => order.orderId,
});

export const { getSelectors } = ordersAdapter;

const ordersSlice = createSlice({
    name: 'accessOrders',
    initialState: ordersAdapter.getInitialState({
        loaded: false,
        loading: false,
        error: null,
        count: undefined,
    }),
    reducers: {
        /* eslint-disable no-param-reassign */
        requestedAll(state) {
            state.loading = true;
        },
        fetchedAll(state, action) {
            state.loading = false;

            state.loaded = true;
            state.count = action.payload.count;
            ordersAdapter.setAll(state, action.payload.orders);
        },
        failed(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
        /* eslint-enable no-param-reassign */
    },
});

const { actions } = ordersSlice;

export default ordersSlice;

export function getAccessOrders(params) {
    return async (dispatch) => {
        let orders;

        dispatch({ type: actions.requestedAll.type });
        try {
            const { orders, count } = await api.teal.getAllOrders(params);
            dispatch({ type: actions.fetchedAll.type, payload: { orders, count } });
        } catch(error) {
            dispatch({ type: actions.failed.type, payload: { error } });
            return error;
        }

        return orders;
    };
}
