import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Section from 'components/Section';

import PdfDialog from 'components/PdfDialog';
import { getSharing } from 'actions/access';

import { config } from 'utils/config';

const PenlyAccessSharing = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const [sharing, setSharing] = useState();
    const [snack, setSnack] = useState();
    const [pdf, setPdf] = useState();

    useEffect(() => {
        const fetch = async () => {
            const sharing = await dispatch(getSharing(params.sharingId));
            setSharing(sharing);
        };
        fetch();
    }, [dispatch, params]);

    if (!sharing) return null;

    return (
        <div className="App-content">
            { sharing.error === 'NotFound' && (
                <Section title="Deling ikke fundet">
                    <p>Delingen eksisterer ikke. Gå tilbage for at finde hvad du leder efter.</p>
                </Section>
            ) }

            { sharing.status === 'pending' && (
                <Section title="Status: Afventer accept" >
                    <p>Email: { sharing.exporter.email }</p>
                    <p>Anmodet adgange: [
                        { Object.keys(sharing.requestedScopes[0]).filter(s => s !== 'type').join(', ') }
                        ]</p>
                    <p>Anmodet dato: { sharing.createdAt }</p>
                    { sharing?.context?.messageToAdvisor && (
                        <>
                            <p>Besked:</p>
                            <p style={{ whiteSpace: 'pre-line', border: '1px solid black', width: 500, padding: 5 }}>{ sharing.context.messageToAdvisor }</p>
                        </>
                    ) }
                </Section>
            ) }

            { sharing.status === 'accepted' && (
                <Section title="Status: Accepteret">
                    <p>Name: { sharing.exporter.name }</p>
                    <p>Adgange: [
                        { Object.keys(sharing.acceptedScopes[0]).filter(s => s !== 'type').join(', ') }
                        ]</p>
                    <p>Email: { sharing.exporter.email }</p>
                    <p>Anmodet dato: { sharing.createdAt }</p>
                    <p>Udløber dato: { sharing.expiry }</p>
                    { sharing?.context?.messageToAdvisor && (
                        <>
                            <p>Besked:</p>
                            <p style={{ whiteSpace: 'pre-line', border: '1px solid black', width: 500, padding: 5 }}>{ sharing.context.messageToAdvisor }</p>
                        </>
                    ) }
                </Section>
            ) }

            { config.env === 'dev' && (
                <Section title="Raw debug">
                    <textarea rows={ 8 } cols={ 80 } defaultValue={ JSON.stringify(sharing, null, 2) }/>
                </Section>
            ) }

            <Snackbar
                open={ !!snack }
                autoHideDuration={ 6000 }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={ () => setSnack() }
            >
                <MuiAlert severity={ snack?.severity || 'success' }>{ snack?.message }</MuiAlert>
            </Snackbar>

            <PdfDialog
                pdf={ pdf }
                onClose={ setPdf }
            />
        </div>
    );
};

export default PenlyAccessSharing;
