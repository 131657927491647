
export const messageWithUser = (userName, advisorName ) =>  `Kære ${userName ?? 'Navn'} 

Til brug for din nedsparingsrådgivning, har vi brug for, at du deler dine pensions-data med Penly rådgiveren.

Da du allerede har en profil på Penly, skal du blot logge ind via knappen nedenfor, så bliver du hjulpet igennem datadelingen.

Har du spørgsmål, er du velkommen til at svare tilbage på denne e-mail.

Mvh ${advisorName ?? 'Kourosh'}`;

export const messageWithoutUser =  (userName, advisorName ) =>  `Kære ${userName ?? 'Navn'} 

Til brug for din nedsparingsrådgivning, har vi brug for, at du deler dine pensions-data med Penly rådgiveren.

3 nemme skridt til at dele dine data

1. Klik på knappen nedenfor og opret en gratis profil på Penly.
2. Upload din PensionsInfo fil. Husk at sætte flueben på alle udbetalingsaldre.
3. Tryk "Accepter deling"

Dine data bliver nu sendt til rådgiveren via vores sikre systemer.

Har du spørgsmål, er du velkommen til at svare tilbage på denne e-mail.

Mvh ${advisorName ?? 'Kourosh'}`;

export const followUpMessageWithName =  (userName, advisorName ) =>  `Kære ${userName ?? 'Navn'} 

Tak for din bestilling af nedsparingsrådgivning hos Penly. 

Jeg har netop sendt dig en anmodning om at oprette en profil på penly.dk, og uploade og dele din pensionsinfo fil. 
Vi har også brug for din partners pensionsinfo data til mødet. Kan jeg derfor bede dig om at sende mig din partners e-mail adresse, så jeg kan invitere til at oprette profil og dele data.

Alternativt kan du også blot sende mig jeres PensionsInfo filer her på e-mail. Husk at sætte flueben på udbetalinger for alle aldre, når I henter PenisonInfo filen. 

Mvh ${advisorName ?? 'Kourosh'}`;

export const followUpMessageUncertain =  (userName, advisorName ) =>  `Kære ${userName ?? 'Navn'} 

Tak for jeres bestilling af nedsparingsrådgivning hos Penly. 

Jeg har netop sendt jer en anmodning om at oprette en profil på penly.dk, og uploade og dele jeres pensionsinfo filer.

Alternativt kan I også blot sende mig jeres PensionsInfo filer her på e-mail. Husk at sætte flueben på udbetalinger for alle aldre, når I henter PenisonInfo filen. 

Mvh ${advisorName ?? 'Kourosh'}`;

export default { messageWithUser, messageWithoutUser, followUpMessageWithName, followUpMessageUncertain };
