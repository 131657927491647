import moment from 'moment-timezone';

export const renderDateLong = (date, timezone = 'Europe/Copenhagen') => {
    const actualDate = moment(date).tz(timezone).format();
    return new Date(actualDate).toLocaleDateString('da-DK', { weekday: 'long', month: 'long', day: 'numeric' });
};
export const renderTime = (date, timezone = 'Europe/Copenhagen') => {
    const actualDate = moment(date).tz(timezone).format();
    return new Date(actualDate).toLocaleString('da-DK', { hour: '2-digit', minute: '2-digit' });
};
export const renderDateTimeLong = (date) => new Date(date).toLocaleDateString('da-DK', { weekday: 'long', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const renderKr = (x, label = 'kr.', maximumDigits = 0, minimumDigits = 0, showSmallMillion = false) => {
    const value = x;

    if (value === undefined) return 0;

    if (showSmallMillion) return `${Math.round(x / 1000000)} m.`;

    return `${value.toLocaleString('da', {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: maximumDigits,
    })}${label && ` ${label}`}`;
};

export const getFirstName = (fullName) => {
    // Split the full name by spaces
    const nameParts = fullName.split(' ');

    // Check if there are at least two parts (first name and last name)
    if (nameParts.length >= 2) {
        // The first part is the first name
        return nameParts[0];
    }
    // If there's only one part, it's considered the first name
    return nameParts[0];

};
