import { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import persistor from './persistor';

export default function useAuthentication() {
    const { initialized, keycloak } = useKeycloak();
    const { authenticated } = keycloak;

    const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';


    const methods = {
        login: useCallback(({ redirectPath = '/' } = {}) => {
            keycloak.login({ redirectUri: `${origin}/${redirectPath}` });
        }, [keycloak, origin]),
        register: useCallback(({ redirectPath = '/' } = {}) => {
            keycloak.register({ redirectUri: `${origin}/${redirectPath}` });
        }, [keycloak, origin]),
        logout: useCallback(({ redirectPath = '' } = {}) => {
            persistor.resetTokens();
            keycloak.logout({ redirectUri: `${origin}/${redirectPath}` });
        }, [keycloak, origin]),
        updateToken: useCallback((seconds = 5) => {
            keycloak.updateToken(seconds);
        }, [keycloak]),
    };

    return { initialized, authenticated, ...methods };
}
