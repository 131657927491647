import axios from 'axios';
import { config } from 'utils/config';
import persistor from 'utils/persistor';

const tealRequest = axios.create({ baseURL: config.apiUrl });
const penlyRequest = axios.create({ baseURL: config.penlyApiUrl });

tealRequest.interceptors.request.use(requestConfig => {
    const { token } = persistor.getTokens();
    requestConfig.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
    return requestConfig;
});

penlyRequest.interceptors.request.use(requestConfig => {
    const { token } = persistor.getTokens();
    requestConfig.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
    return requestConfig;
});

async function getAllScrapes() {
    const response = await tealRequest('/pensions-info-scrapes');
    return response.data.scrapes;
}
async function getAllScrapesByPenlyUserId({ penlyUserId }) {
    const response = await tealRequest(`/pensions-info-scrapes/by-penly-userid/${penlyUserId}`);
    return response.data.scrapes;
}
async function getScrape({ scrapeId }) {
    const response = await tealRequest(`/pensions-info-scrapes/${scrapeId}`);
    return response.data.scrape;
}

async function getScrapeFile({ scrapeId }) {
    const response = await tealRequest(`/pensions-info-scrapes/${scrapeId}/file`, { responseType: 'blob' });
    return response.data;
}

async function createScrape({ pdf }) {
    const formData = new FormData();
    formData.set('pensionsInfoPdf', pdf);

    const response = await tealRequest({
        method: 'post',
        url: '/pensions-info-scrapes',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data.scrape;
}

async function convertContentToScrape({ content, fileId, penlyUserId }) {
    const response = await tealRequest({
        method: 'post',
        url: '/pensions-info-scrapes/with-content',
        data: { content, fileId, penlyUserId },
    });
    return response.data.scrape;
}

async function deleteScrape({ scrapeId }) {
    const response = await tealRequest({
        method: 'delete',
        url: `/pensions-info-scrapes/${scrapeId}`,
    });
    return response.data.scrape;
}

async function getUser() {

    const { data }  = await tealRequest.get('/user');
    return data.user;
}

async function createOrganisation(organisation) {
    const { data } = await tealRequest({
        method: 'post',
        url: '/organisation',
        data: organisation,
    });

    return data.organisation;
}

async function createSharing(sharing) {
    const { data } = await tealRequest({
        method: 'post',
        url: '/access/sharings',
        data: sharing,
    });

    return data.sharing;
}
async function updateIntegration(type, config) {
    const { data } = await tealRequest({
        method: 'put',
        url: `/integration/${type}`,
        data: config,
    });

    return data;
}
async function getIntegrations() {
    const { data }  = await tealRequest.get('/integration');
    return data.integrations;
}

async function getAllSharings() {
    const { data } = await tealRequest('/access/sharings');

    return data.sharings;
}

async function getAllOrders(params) {
    const { data : { orders, count } } = await tealRequest('/access/orders', { params } );

    return { orders, count };
}

async function getAllOrganisationUsers() {
    const { data : { orders } } = await tealRequest('/organisation/users');

    return { orders };
}

async function getAccessUsers() {
    const result = await tealRequest('/access/users');
    return result.data.users;
}

async function getSharing(sharingId) {
    let sharing;
    try {
        const result = await tealRequest(`/access/sharings/${sharingId}`);
        sharing = result.data.sharing;
    } catch (error) {
        if (error.response.status === 404) return { sharingId, error: 'NotFound' };
        throw error;
    }

    return sharing;
}

async function getOrder(orderId) {
    let order;
    try {
        const result = await tealRequest(`/access/orders/${orderId}`);
        order = result.data.order;
    } catch (error) {
        if (error.response.status === 404) return { order, error: 'NotFound' };
        throw error;
    }

    return order;
}

export async function updateOrder(orderId, partialOrderData) {
    const { data } = await tealRequest({
        url: `/access/orders/${orderId}`,
        method: 'patch',
        data: partialOrderData,
    });

    return data.order;
}

async function getSharings(userId) {
    let result;
    try {
        result = await tealRequest.get('/access/sharings', { params: { userId } });
    } catch (error) {
        if (error.response.status === 404) return { userId, error: 'NotFound' };
        throw error;
    }

    return result.data.sharings;
}

async function getPenlyUser(userId) {
    const response = await penlyRequest({
        url: '/user',
        headers: { 'x-user-id': userId },
    });

    return response.data.user;
}

async function getFiles(userId) {
    const response = await penlyRequest({
        url: '/user/files',
        headers: { 'x-user-id': userId },
    });
    return response.data.files;
}

async function uploadFile(userId, file) {
    const formData = new FormData();
    formData.append('json', JSON.stringify({ fileType: file.type }));
    formData.append('file', file.file);
    const { data } = await penlyRequest({ method: 'POST', url: '/user/files', data: formData, headers: { 'x-user-id': userId } });
    return data.file;
}

async function deleteFile(userId, fileId) {
    await penlyRequest({ method: 'DELETE', url: `/user/files/${fileId}`, headers: { 'x-user-id': userId } });
    return fileId;
}

async function getConversations(userId) {
    const response = await penlyRequest({
        url: '/user/conversations',
        headers: { 'x-user-id': userId },
    });
    return response.data.conversations;
}
async function createMessage(userId, messageData) {
    const { data } = await penlyRequest({
        method: 'post',
        headers: { 'x-user-id': userId },
        url: '/user/conversations/messages',
        data: messageData,
    });
    return data.message;
}

async function getFileBinary(userId, fileId) {
    const response = await penlyRequest({
        url: `/user/files/${fileId}/binary`,
        headers: { 'x-user-id': userId },
        responseType: 'blob',
    });
    return response.data;
}
async function invitePartnerToKreditdata(userId, data) {
    const response = await penlyRequest({
        method: 'post',
        url: '/user/integrations/kreditdata/partner/pensionsInfo',
        headers: { 'x-user-id': userId },
        data,
    });

    return response.data.link;
}


export const teal = {
    getUser,
    createOrganisation,
    getAllScrapes,
    getAllScrapesByPenlyUserId,
    getScrape,
    getScrapeFile,
    createScrape,
    convertContentToScrape,
    deleteScrape,
    createSharing,
    getSharing,
    getSharings,
    getAllSharings,
    getAccessUsers,
    getAllOrders,
    getAllOrganisationUsers,
    getOrder,
    updateOrder,
    updateIntegration,
    getIntegrations,
};

export const penly = {
    getUser: getPenlyUser,
    getFiles,
    getFileBinary,
    uploadFile,
    deleteFile,
    getConversations,
    createMessage,
    invitePartnerToKreditdata,
};

export default { teal, penly };
