import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';

import api from 'api';

const usersAdapter = createEntityAdapter({
    selectId: (users) => users.userId,
});

export const { getSelectors } = usersAdapter;

const usersSlice = createSlice({
    name: 'organisationUsers',
    initialState: usersAdapter.getInitialState({
        loaded: false,
        loading: false,
        error: null,
    }),
    reducers: {
        /* eslint-disable no-param-reassign */
        requestedAll(state) {
            state.loading = true;
        },
        fetchedAll(state, action) {
            state.loading = false;

            state.loaded = true;
            usersAdapter.setAll(state, action.payload.users);
        },
        failed(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
        /* eslint-enable no-param-reassign */
    },
});

const { actions } = usersSlice;

export default usersSlice;

export function getOrganisationUsers() {
    return async (dispatch) => {
        let users;

        dispatch({ type: actions.requestedAll.type });
        try {
            const { users } = await api.teal.getAllOrganisationUsers();
            dispatch({ type: actions.fetchedAll.type, payload: { users } });
        } catch(error) {
            dispatch({ type: actions.failed.type, payload: { error } });
            return error;
        }

        return users;
    };
}
