import React, { useEffect, useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import GroupIcon from '@mui/icons-material/Group';
import paths from 'paths';
import { getAccessUsers } from 'actions/access';

import UserInviteForm from './UserInviteForm';

const Users = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [groupedUsers, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showRequestModal, setShowRequestModal] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const users = (await dispatch(getAccessUsers())).filter((x) => !!x.email);
            setUsers(groupUsersByPartner(users));
            setLoading(false);
        };
        fetch();
    }, [dispatch]);

    const handleOpenRequestModal = () => {
        setShowRequestModal(true);
    };

    const handleCloseRequestModal = () => {
        setShowRequestModal(false);
    };

    const organisation = useSelector(state => state.auth.user.organisation);
    const showPenlyAccessInvite = location.search === '?showRequestModal=true' || organisation.enabledFeatures?.includes('PenlyAccessInvite');

    const getUserPath = (userId) => paths.penlyAccessUser.getPath(userId);

    return (
        <>
            { loading && (
                <div className="App-section-loading">
                    <CircularProgress  color="primary" size={ 40 } />
                </div>
            ) }
            { !loading && (
                <>
                    { showPenlyAccessInvite &&
                        <Button variant="contained" onClick={ handleOpenRequestModal }>
                            Inviter bruger
                        </Button>
                    }

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Navn</TableCell>
                                    <TableCell align="left">Sidst opdateret</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left">BrugerId</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <>
                                { groupedUsers.map((users, i) => (
                                    <TableBody key={ i }>
                                        { users.length > 1 && (
                                            <TableRow style={{ position: 'relative' }}>
                                                <td><GroupIcon sx={{ position: 'absolute', top: 37, left: 1 }} /></td>
                                            </TableRow>
                                        ) }
                                        { users.toReversed().map((user) => (
                                            <TableRow key={ user.userId }>
                                                <TableCell align="left">{ user.name }</TableCell>
                                                <TableCell align="left">{ new Date(user.sharingUpdatedAt).toLocaleDateString('da-DK', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }</TableCell>
                                                <TableCell align="left">{ user.email }</TableCell>
                                                <TableCell align="left">{ user.userId ?? 'Afventer deling' }</TableCell>
                                                <TableCell sx={{ p: 0 }} align="right">
                                                    { user.userId && (
                                                        <Button component={ RouterLink } to={{ pathname: getUserPath(user.userId) }} variant="contained">Åben</Button>
                                                    ) }
                                                </TableCell>
                                            </TableRow>
                                        )) }
                                        { users.length > 1 && (
                                            <TableRow key={ i }>
                                                <TableCell colSpan={ 5 }></TableCell>
                                            </TableRow>
                                        ) }
                                    </TableBody>
                                )) }
                            </>
                        </Table>
                    </TableContainer>
                </>
            ) }
            <Modal open={ showRequestModal } onClose={ handleCloseRequestModal }>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'scroll',
                        maxHeight: '95vh',
                    }}
                >
                    <UserInviteForm onClose={ handleCloseRequestModal } />
                </Box>
            </Modal>
        </>
    );
};

export default Users;

const groupUsersByPartner = (users) => {
    const groupedUsers = [];
    // first find all users who has been invited by a partner
    users
        .filter((user) => !!user.initiator?.userId)
        .forEach((user) => {
            groupedUsers.push([user]);
        });
    // then take all users who has not been invited by a partner, and add them to a group and or create a 1-person group
    users
        .filter((user) => !user.initiator?.userId)
        .forEach((user) => {
            const existingGroup = groupedUsers.find(group => group.some(u => u.initiator?.userId === user.userId && user.userId));                if (existingGroup) {
                existingGroup.push(user);
            } else {
                groupedUsers.push([user]);
            }
        });

    return groupedUsers;
};
