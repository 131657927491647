import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Confirm = function Confirm({ open, handleClose, onConfirm, onCancel, text }) {
    const onConfirmDialog = () => {
        handleClose();
        onConfirm();
    };

    const onCancelDialog = () => {
        handleClose();
        onCancel();
    };

    return (
        <div>
            <Dialog open={ open } onClose={ handleClose }>
                <DialogTitle>{ text.title }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { text.content }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={ onCancelDialog } color="primary">
                        { text.no }
                    </Button>
                    <Button variant='outlined' onClick={ onConfirmDialog } color="primary" autoFocus>
                        { text.yes }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

Confirm.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    text: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        yes: PropTypes.string,
        no: PropTypes.string,
    }),
};

export default Confirm;
