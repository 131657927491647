import initialState from './initial';

// eslint-disable-next-line default-param-last
export default (state = initialState.auth, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'AUTH_UPDATED': {
            return { ...state, ...payload, user: { ...state.user, ...payload.user } };
        }
        default:
            return state;
    }
};
