import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getScrape } from 'actions/scrapes';
import { useParams } from 'react-router-dom';
import Section from 'components/Section';
import Content from 'components/Content';

import AftalerTable from './AftalerTable';
import ProfilTable from './ProfilTable';
import ScrapeDetails from './ScrapeDetails';
import UdbetalingerTable from './UdbetalingerTable';
import PensionFlereOplysningerTable from './PensionFlereOplysningerTable';

function PensionsInfoScrapePage() {
    const dispatch = useDispatch();
    const [scrape, setScrape] = useState();
    const { scrapeId } = useParams();

    useEffect(() => {
        const run = async () => {
            const scrape = await dispatch(getScrape(scrapeId)); // eslint-disable-line no-shadow
            setScrape(scrape);
        };
        run();
    }, [dispatch, scrapeId]);

    if (scrape?.status === 'error') {
        return (
            <Content>
                <Section
                    centered
                    title={ 'Ikke fundet' }
                    description="Scrapet blev ikke fundet">
                </Section>
            </Content>
        );
    }

    if (scrape?.status === 'failed') {
        return (
            <Content>
                <Section centered title='Fejl'>
                    { scrape.error ? `Fejl: ${scrape.error}` : 'Ukendt årsag. Kontakt support.' }
                </Section>
                <Section title='Detaljer' >
                    <ScrapeDetails scrape={ scrape } />
                </Section>
            </Content>
        );
    }
    return (
        <Content>
            <Section title='Detaljer' loading={ !scrape } >
                <ScrapeDetails scrape={ scrape } />
            </Section>

            { scrape && scrape.content && (
                <>
                    <Section title='Profil' >
                        <ProfilTable profil={ scrape.content.profil } />
                    </Section>

                    <Section centered title='Aftaler' >
                        <AftalerTable aftaler={ scrape.content.aftaler } />
                    </Section>
                    { scrape.content.pensionFlereOplysninger &&
                        <Section centered title='Pension, flere oplysninger' >
                            <PensionFlereOplysningerTable pensionFlereOplysninger={ scrape.content.pensionFlereOplysninger } />
                        </Section>
                    }

                    { scrape.content.udbetalinger.map(u => (
                        <Section
                            key={ u.pensionsAlder }
                            centered
                            title={ `Udbetalinger, pension som ${  u.pensionsAlder  }-årig` }
                        >
                            <UdbetalingerTable udbetalingsRaekker={ u.udbetalingsRaekker } perioder={ u.perioder } />
                        </Section>
                    )) }
                </>
            ) }
        </Content>
    );
}

export default PensionsInfoScrapePage;
