import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { teal } from 'api';

const productOptions = [
    {
        'id': 'pension-advisory-full',
        'categories': [
            'pension',
            'featured',
        ],
        'slug': 'pensionstjek-fuld',
        'name': 'Nedsparingsrådgivning',
        'type': 'product',
        'price': {
            'standard': 7499,
        },
        'highlight': 'Normalpris 7.499 kr.',
        'cta': 'Vælg',
        'receipt': {
            'text': '\n## Tak for din bestilling\n\nVælg et tidspunkt for mødet med rådgiveren. Mødet foregår online.\n                ',
            'cta': {
                'text': 'Book møde i Calendly nu',
                'url': 'https://calendly.com/d/29r-n2c-vgh/nedsparingsradgivning-opstartsmode',
            },
        },
        'descriptionShort': 'Få overblik over din pensionsøkonomi og optimer dine udbetalinger.',
        'description': '<br/><strong>Overblik</strong> <p>Budgettet giver dig overblik over, hvor mange penge, du har at gøre godt med i årene som pensionist.</p> <strong>Optimering</strong> <p>Du får styr på, i hvilken rækkefølge, du skal bruge dine penge, så du får mest ud af dem.</p> <strong>Optimal opsparing</strong> <p>Du får rådgivning om, hvordan du sparer bedst op i årene op til pension, så du får flere penge til dig selv.</p> <br/> <p>Er relevant for dig, der vil have mest ud af dine penge og overblik over din økonomi.</p>',
        'valueDescription': 'Rabat på nedsparingsrådgivning',
        'advisorId': 'penly-konsulenterne',
        'productId': 'pension-advisory-full',
    },
    {
        'hidden': false,
        'id': 'pension-advisory-hourlyrate',
        'categories': [
            'pension',
            'featured',
        ],
        'slug': 'pensionstjek-time',
        'name': 'Pensionsrådgivning',
        'type': 'product',
        'price': {
            'standard': 1499,
            'hourly': true,
        },
        'highlight': 'Uvildig pensionsrådgivning',
        'cta': 'Vælg',
        'descriptionShort': 'Rådgiveren kigger efter optimeringsmuligheder, så du får mere ud af dine pensioner.',
        'description': '<br/><p><strong>Rådgiveren kigger bl.a. på:</strong></p><p>Om sammensætningen mellem dine forskellige typer pensioner er fornuftig?</p><p>Om du er over- eller underforsikret?</p><p>Om du betaler for meget for dine pensioner og forsikringer?</p><p>Om det giver mening at flytte eller sammenlægge dine pensioner?</p>',
        'valueDescription': 'Pensionsrådgivning på timebasis',
        'advisorId': 'penly-konsulenterne',
        'receipt': {
            'text': '\n## Tak for din bestilling\n\nVælg et tidspunkt for mødet med rådgiveren. Mødet foregår online.\n                                ',
            'cta': {
                'text': 'Book møde i Calendly nu',
                'url': 'https://calendly.com/penly/pensionsradgivning-1-time',
            },
        },
        'productId': 'pension-advisory-hourlyrate',
    },
    {
        'hidden': false,
        'id': 'economy-advisory-full',
        'categories': [
            'economy',
            'featured',
        ],
        'slug': 'oekonomitjek',
        'name': 'ØkonomiTjek',
        'type': 'product',
        'price': {
            'standard': 3999,
        },
        'highlight': 'Uvildig rådgivning',
        'cta': 'Vælg',
        'descriptionShort': 'Få svar på, om der er penge at spare eller tjene i din nuværende økonomi.',
        'description': '<br/><p><strong>Få overblik over, hvor du kan forbedre din økonomi indenfor:</strong></p></p><p><strong>Bolig-, realkredit- og øvrige lån</strong></p><p>Har du de rigtige lån, eller er der mulighed for optimering eller afdragsfrihed?</p></br><p><strong>Pension og pensionsforsikringer</strong></p><p>Sparer du rigtigt op i forhold til din alder og økonomi? Er du rigtigt forsikret og hvordan er dine nærmeste stillet ved sygdom eller dødsfald?</p><br><p><strong>Gældssammensætning og afvikling</strong></p><p>Vi ser på samspillet i din økonomi og vurderer hvilken gæld, du skal afvikle først.</p><br><p><strong>Opsparing og investering</strong></p><p>Har du en fornuftig balance mellem opsparing og investering?</p><br><p>Er relevant for dig, der vil have overblik over din samlede økonomi og tryghed i at vide, at du bruger dine penge optimalt.</p>',
        'valueDescription': 'Få overblik med et ØkonomiTjek',
        'advisorId': 'penly-konsulenterne',
        'goToCustomFlow': true,
        'receipt': {
            'text': '\n## Vi glæder os til at tage dig igennem ØkonomiTjekket.\n\nVælg et tidspunkt for det første møde med konsulenten. Mødet foregår online og tager ca. 30 minutter.\n                ',
            'cta': {
                'text': 'Book møde i Calendly nu',
                'url': 'https://calendly.com/penly/opstartsmoede-oekonomitjek',
            },
        },
        'productId': 'economy-advisory-full',
    },
];

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#F44336',
        '&:hover': {
            background: '#f00',
        },
        color: 'white',
    },
    formControl: {
        marginTop:20,
        minWidth: 120,
    },
}));
const AddProductsModalButton = props => {
    const [open, setOpen] = React.useState(false);
    const [product, setProduct] = React.useState(productOptions[0]);

    const styles = useStyles();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleAccept = async () => {
        try {
            const items = [ ...props.order.items, { product: { ...product, addedByAdvisor: true }, quantity: 1 } ];
            const updatedOrder = await teal.updateOrder(props.order.orderId, { items });
            props.onClick(updatedOrder);
        } catch (error) {
            alert('kunne ikke tilføje produkt');
        }
        setOpen(false);

    };

    const handleChange = (event) => {
        setProduct(productOptions.find(x => x.id === event.target.value));
    };
    const handlePrice = (event) => {
        setProduct({ ...product, price: { ...product.price, now: Number(event.target.value) } });
    };
    return (
        <div>
            <Button onClick={ handleClickOpen } style={{}} variant="contained">
                Tilføj produkt
            </Button>
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Tilføj produkt til ordren</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Kunden vil modtage email om ny bestilling</DialogContentText>
                    <DialogContentText id="alert-dialog-description">Email inkluderer ikke link til mødebooking eller indhentning af mere data.</DialogContentText>
                    <DialogContentText id="alert-dialog-description">Tilkøb af jura produkter skal bestilles på Penly.dk</DialogContentText>

                    <FormControl className={ styles.formControl }>
                        <InputLabel id="select-label">Vælg produkt</InputLabel>
                        <Select
                            labelId="select-label"
                            label="Vælg produkt"
                            value={ product.id }
                            onChange={ handleChange }
                        >
                            <MenuItem disabled selected value="default">Vælg produkt</MenuItem>
                            { productOptions.map(({ id, name }) => (
                                <MenuItem value={ id } key={ id }>
                                    { name }
                                </MenuItem>
                            )) }
                        </Select>
                    </FormControl>
                    <div style={{ marginTop:20 }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="component-simple">{ product.price.hourly? 'Timepris' : 'Pris' } </InputLabel>
                            <Input endAdornment={ <InputAdornment position="end">kr.</InputAdornment> } id="component-simple" value={ product.price.now || product.price.standard } onChange={ handlePrice } />
                        </FormControl>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color="warning">
                        { props.disagreeButtonText }
                    </Button>
                    <Button onClick={ handleAccept } autoFocus color="primary"  variant="contained">
                        { props.agreeButtonText }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

AddProductsModalButton.defaultProps = {
    agreeButtonText: 'Tilføj',
    disagreeButtonText: 'Fortryd',
};

AddProductsModalButton.propTypes = {};

export default AddProductsModalButton;
