import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingDimmer({ text }) {
    return (
        <div style={{ padding: '0px' }} className="ui active dimmer inverted">
            { text }
            <CircularProgress color="primary" size={ 40 }/>
        </div>
    );
}

LoadingDimmer.propTypes = {
    text: PropTypes.string,
};

export default LoadingDimmer;
