export const statusOptions =   [
    { name: 'Bestilt', value: 'ordered' },
    { name: 'Første møde', value: 'firstMeeting' },
    { name: 'Andet møde', value: 'secondMeeting' },
    { name: 'Tredje møde', value: 'thirdMeeting' },
    { name: 'Færdig', value: 'completed' },
    { name: 'På pause', value: 'onHold' },
    { name: 'Annulleret', value: 'cancelled' },
    { name: 'Refunderet', value: 'refunded' },
];

// export const advisorOptions = [
//     { name: 'Advisor 1', value: 'advisor1' },
//     { name: 'Advisor 2', value: 'advisor2' },
//     { name: 'Advisor 3', value: 'advisor3' },
// ];
export const legalProducts = [
    { name: 'Gennemgang af testamente', value: 'existing-testament' },
    { name: 'Testamente', value: 'new-testament' },
    { name: 'Familiejura Gennemgang', value: 'family-jura' },
    { name: 'Ægtepagt', value: 'prenuptial-agreement' },
    { name: 'Fremtidsfuldmagt', value: 'power-of-attorney' },
];
export const financialProducts = [
    { name: 'ØkonomiTjek', value: 'economy-advisory-full' },
    { name: 'PensionsTjek gennemgang', value: 'pension-advisory-pensioncheck' },
    { name: 'Nedsparingsrådgivning', value: 'pension-advisory-full' },
    { name: 'Pensionsrådgivning', value: 'pension-advisory-hourlyrate' },
];
