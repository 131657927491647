import { teal } from 'api';
import { getUser } from 'actions/auth';

export function createOrganisation(organisation) {
    return async (dispatch) => {
        try {
            await teal.createOrganisation(organisation);
        } catch(error) {
            return error;
        }

        dispatch(getUser());
    };

    // return { type: 'ORGANISATION_CREATE', payload: { organisation } };
}
