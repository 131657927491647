import request from 'axios';
import { config } from 'utils/config';

window.onerror = (message, url, lineNo, columnNo, error) => {
    request({
        method: 'POST',
        url: `${config.apiUrl}/errors`,
        data: {
            message,
            url,
            lineNo,
            columnNo,
            href: document.location.href,
            error: !error ? null : {
                name: error.name,
                message: error.message,
                stack: error.stack,
                stack2: error.stack2,
            },
        },
    });

    return false;
};
