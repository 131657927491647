export const colors = {
    main: '#009094',
    primary: '#4E7EC2',
    secondary: '#808080',
    tertiary: '#00C49F',
    quaternary: '#0088FE',
    quinary: '#F3F4F8',
    red: '#FF0000',
    yellow: '#FBBD08',
    green: '#21BA45',
    white: '#FFF',
};

export default {
    palette: {
        primary: {
            main: colors.main,
        },
    },
    overrides: {
        MUIRichTextEditor: {
            root: {
                minHeight: '300px',
                width: '100%',
                border: '1px solid #eeeeee',
                padding: '12px',
                borderRadius:'8px',
            },
            editor: {
                height: '100%',
            },

        },
    },

};
