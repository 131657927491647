import Section from 'components/Section';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getConversations } from 'actions/access';
import { useParams } from 'react-router';
import PenlyAccessUserMessages from './PenlyAccessUserMessages';
import PenlyAccessUserNotes from './PenlyAccessUserNotes';

const PenlyAccessMessages = ({ penlyUser }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const params = useParams();
    const [messages, setUserMessages] = useState([]);
    const [notes, setUserNotes] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            const conversations = (await dispatch(getConversations(penlyUser.userId)));
            const m = conversations?.filter(x => x.audience ==='all')[0]?.messages ?? [];
            const n = conversations?.filter(x => x.audience ==='importer')[0]?.messages ?? [];
            setUserMessages(m);
            setUserNotes(n);
        };
        fetch();
    }, [dispatch, params]);
    return (
        <>
            <Section title="Egne noter">
                <PenlyAccessUserNotes init={ notes } user={ user } penlyUser={ penlyUser }></PenlyAccessUserNotes>
            </Section>
            <Section title="Brugerbeskeder">
                <PenlyAccessUserMessages init={ messages } user={ user } penlyUser={ penlyUser }></PenlyAccessUserMessages>
            </Section>
        </>
    );
};


export default PenlyAccessMessages;
