import * as api from '../api';

export function getAllSharings() {
    return async () => {
        const sharings = await api.teal.getAllSharings();
        return sharings;
    };
}

export function createSharing(sharingData) {
    return async (dispatch) => {
        let sharing;
        try {
            sharing = await api.teal.createSharing(sharingData);
        } catch(error) {
            dispatch({ type: 'ACCESS_CREATE_SHARING_FAILED', payload: { error } });
            return error;
        }

        return sharing;
    };
}

export function getSharing(sharingId) {
    return async (dispatch) => {
        let sharing;
        try {
            sharing = await api.teal.getSharing(sharingId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_SHARING_FAILED', payload: { error } });
            return error;
        }

        return sharing;
    };
}

export function getSharings(userId) {
    return async (dispatch) => {
        let sharings;
        try {
            sharings = await api.teal.getSharings(userId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_SHARINGS_FAILED', payload: { error } });
            return error;
        }

        return sharings;
    };
}

export function getUser(userId) {
    return async (dispatch) => {
        let user;
        try {
            user = await api.penly.getUser(userId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_USER_FAILED', payload: { error } });
            return error;
        }

        return user;
    };
}

export function getFiles(userId) {
    return async (dispatch) => {
        let files;
        try {
            files = await api.penly.getFiles(userId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_FILES_FAILED', payload: { error } });
            return error;
        }

        return files;
    };
}

export function uploadFile(acceptedFiles, rejected, type, userId) {
    return async () => {
        if (rejected.length) return alert('Fejl ved upload');
        const file = acceptedFiles[0];
        const filename = file.name;
        const fileInfo = { file, filename, type };

        const uploadedFile = await api.penly.uploadFile(userId, fileInfo);
        return uploadedFile;
    };
}
export function deleteFile(userId, fileId) {
    return async (dispatch) => {
        let files;
        try {
            files = await api.penly.deleteFile(userId, fileId);
        } catch(error) {
            dispatch({ type: 'ACCESS_DELETE_FILE_FAILED', payload: { error } });
            return error;
        }

        return files;
    };
}



export function getFileBinary(userId, fileId) {
    return () => api.penly.getFileBinary(userId, fileId);
}

export function getAccessUsers() {
    return () => api.teal.getAccessUsers();
}
export function getConversations(userId) {
    return async (dispatch) => {
        let conversations;
        try {
            conversations = await api.penly.getConversations(userId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_CONVERSATIONS_FAILED', payload: { error } });
            return error;
        }

        return conversations;
    };
}

export function createMessage(userId, messageData) {
    return async (dispatch) => {
        let message;
        try {
            message = await api.penly.createMessage(userId, messageData);
        } catch(error) {
            dispatch({ type: 'ACCESS_CREATE_MESSAGE_FAILED', payload: { error } });
            return error;
        }

        return message;
    };
}
