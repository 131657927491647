/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Button } from "@mui/material";
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { useParams } from 'react-router';
import MuiAlert from '@mui/material/Alert';
import { List, ListItem, ListItemText } from '@mui/material';
import { createMessage } from 'actions/access';
import DOMPurify from 'dompurify';
import TextEditor from "./TextEditor"
import { renderDateTime } from 'utils/helpers';
export default function PenlyAccessUserMessages({ user, penlyUser, init }) {
  const [showEditor, setShowEditor] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const subject = 'Ny besked fra din rådgiver, ' + user.name
  const [messages, setMessages] = useState([]);
  const [snack, setSnack] = useState();

  useEffect(() => {
    setMessages(init);
  }, [init]);

  const handleSubmit = async (content) => {
    const messageData = {
      audience: 'all',
      name: user.name,
      message: { content },
    };
    const messageOrError = await dispatch(createMessage(penlyUser.userId, messageData));
    if (messageOrError.name === 'AxiosError') {
      setSnack({message: 'Besked kunne ikke sendes'})
      localStorage.setItem('PenlyAccessUserMessages', JSON.stringify(content));
    } else {
      setShowEditor(false)
      const message = {
        _id: messages.length + 1,
        content,
        sender: { userId: user.userId, name: user.name },
      };
      setMessages([...messages, message]);
    }
  }

  return (
    <div sx={{
      width: '100%',
      backgroundColor: 'background.paper',
    }}>
      <p>Send beskeder til Penly brugeren. Brugeren kan se beskederne på sin Penly profil. Du kan også kopiere beskeder over i din email, og kommunikere med kunden der igennem. </p>
                    <Snackbar
                open={ !!snack }
                autoHideDuration={ 6000 }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={ () => setSnack() }
            >
                <MuiAlert severity={ snack?.severity || 'error' }>{ snack?.message }</MuiAlert>
            </Snackbar>
      <List>
        {messages.map((message) => (
          <ListItem
            key={message._id}
            sx={{
              backgroundColor: 'grey.200',
              padding: 2,
              marginBottom: 2,
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              '&.selectedConversation': {
                // styles for the selected conversation
              },
              '&:hover': {
                // styles for when the item is hovered
              },
            }}
          >


            <ListItemText
              primary={message.sender.name}
              secondary={renderDateTime(message.date)}
              primaryTypographyProps={{ variant: 'h6' }}
              secondaryTypographyProps={{
                variant: 'body2',
              }}
            />
            <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }}
              />
          </ListItem>
        ))}
      </List>
      {
        showEditor ?
          <TextEditor emailButtonText={'Kopiér til email'} saveButtonText={'Send'} contentKey={'PenlyAccessUserMessages'} onSubmit={handleSubmit} onEmail={() => window.location.href = `mailto:${penlyUser.email}?subject=${subject}&body=Hej ${penlyUser.firstName}.`}></TextEditor> :
          <Button variant='outlined' onClick={() => setShowEditor(true)} color="primary"> Ny besked</Button>

      }
    </div>

  )
}

