import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
// import { Document, Page } from 'react-pdf';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import useWindowSize from '../hooks/useWindowSize';

const PdfDialog = ({ pdf, onClose }) => {
    const size = useWindowSize();
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState();

    const onLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    const onForward = () => setPageNumber(pageNumber + 1);
    const onBack = () => setPageNumber(pageNumber - 1);

    return (
        <Dialog
            fullWidth
            maxWidth='xl'
            onClose={ onClose }
            open={ !!pdf?.url }
        >
            <Document
                file={ pdf?.url }
                onLoadSuccess={ onLoadSuccess }
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 5 }}>
                    <h1>{ pdf?.fileName }</h1>
                    <div style={{ borderColor: '#000000', border: 1, borderStyle: 'solid' }}>
                        <Page
                            height={ size.height * 0.80 }
                            pageNumber={ pageNumber }
                        />
                    </div>
                    <p>Side { pageNumber } af { numPages }</p>
                    <div>
                        <Button variant="outlined" style={{ marginRight: 5 }} disabled={ pageNumber === 1 } onClick={ onBack }>Forrige</Button>
                        <Button variant="outlined" disabled={ pageNumber === numPages } onClick={ onForward }>Næste</Button>
                    </div>
                </div>
            </Document>
        </Dialog>
    );
};

export default PdfDialog;

PdfDialog.propTypes = {
    pdf: PropTypes.object,
    onClose: PropTypes.func,
};
